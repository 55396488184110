import React from 'react';

export const LinkRightArrowLarge = () => (
  <svg
    width="40"
    height="28"
    viewBox="0 0 40 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M39.009 14.8204C39.107 14.5839 39.1615 14.3248 39.1623 14.0531C39.1623 14.049 39.1623 14.045 39.1623 14.041C39.1607 13.5259 38.9635 13.0114 38.5705 12.6185L27.142 1.18988C26.353 0.4009 25.0738 0.4009 24.2848 1.18988C23.4958 1.97886 23.4958 3.25804 24.2848 4.04702L32.2645 12.0267L2.85624 12.0267C1.74046 12.0267 0.835937 12.9312 0.835938 14.047C0.835937 15.1628 1.74046 16.0673 2.85624 16.0673H32.2645L24.2848 24.047C23.4958 24.836 23.4958 26.1152 24.2848 26.9042C25.0738 27.6931 26.353 27.6931 27.142 26.9042L38.5695 15.4766C38.571 15.4752 38.5734 15.4727 38.5748 15.4713C38.7664 15.2786 38.9111 15.0569 39.009 14.8204Z"
      fill="#022539"
    />
  </svg>
);
