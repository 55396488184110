import React from 'react';
import { Container, Box } from '@mui/material';
import { MainColumn } from '@/components/main-column';
import { MarkdownContent } from '@/components/markdown-content';
import { StrapiImage } from '@/api/types';
import { IconStack } from '@/components/icon-stack';

type Props = {
  contentBeforeLogos: string;
  contentAfterLogos: string;
  logos: StrapiImage[] | undefined;
  floatingImageMobile: StrapiImage | undefined;
  floatingImageDesktop: StrapiImage | undefined;
};

export const SectionWithFloatingImage = ({
  contentBeforeLogos,
  contentAfterLogos,
  logos,
  floatingImageMobile,
  floatingImageDesktop,
}: Props) => (
  <Container
    disableGutters
    maxWidth={false}
    sx={{
      position: 'relative',
      textAlign: 'right',
    }}
  >
    <Box
      sx={{
        position: { md: 'absolute' },
        width: '100%',
        height: '100%',
        textAlign: 'left',
      }}
    >
      <MainColumn
        sx={{
          display: 'flex',
          alignItems: 'center',
          height: '100%',
          overflow: 'visible',
        }}
      >
        <Box
          sx={{
            width: { md: '54%' },
          }}
        >
          <MarkdownContent
            content={contentBeforeLogos}
            sx={{
              width: '100%',
              typography: 'body2',
              mb: 4,
              'h1, h2, h3, h4, h5, h6': {
                color: 'greyscale.persian',
                mb: 4,
              },
            }}
          />
          {logos && logos.length > 0 && (
            <Box
              sx={{
                mb: 4,
              }}
            >
              <IconStack logos={logos} />
            </Box>
          )}
          <MarkdownContent
            content={contentAfterLogos}
            sx={{
              width: '100%',
              typography: 'body2',
              'h1, h2, h3, h4, h5, h6': {
                color: 'greyscale.persian',
                mb: 3,
              },
            }}
          />
        </Box>
      </MainColumn>
    </Box>
    <Box>
      {
        /*
          * Had to return these to their previous form as I wasn't
          * able to align the images properly using GatsbyImage
          * will attempt later or request assistance
          * to remove the comment after this is changed
          * */
      }
      {floatingImageMobile && (
        <Box
          component="img"
          src={floatingImageMobile.attributes.url}
          sx={{
            px: 6,
            py: 3,
            display: { xs: 'inline', md: 'none' },
            maxWidth: '100%',
          }}
        />
      )}
      {floatingImageDesktop && (
        <Box
          component="img"
          src={floatingImageDesktop.attributes.url}
          sx={{
            display: { xs: 'none', md: 'inline' },
            maxWidth: '45%',
          }}
        />
      )}
    </Box>
  </Container>
);
