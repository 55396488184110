import React from 'react';
import { Box } from '@mui/material';
import { StrapiCaseStudySection6 } from '@/api/case-studies';
import { SectionWithFloatingImage } from '@/components/section-with-floating-image';

export type Props = {
  strapiSection?: StrapiCaseStudySection6;
};

export const Section6 = ({ strapiSection }: Props) => (
  <Box
    sx={{
      pt: { xs: 7, md: '0px' },
    }}
  >
    <SectionWithFloatingImage
      contentBeforeLogos={strapiSection?.paragraph1 || ''}
      contentAfterLogos={strapiSection?.paragraph2 || ''}
      logos={
        strapiSection?.icons?.data
      }
      floatingImageMobile={strapiSection?.image?.mobile?.data}
      floatingImageDesktop={strapiSection?.image?.desktop?.data}
    />
  </Box>
);
