import { styled } from '@mui/system';
import { GatsbyImage } from 'gatsby-plugin-image';

export const StyledImage1 = styled(GatsbyImage)({
  height: '100%',
  width: '100%',
  position: 'absolute',
  top: 0,
  left: 0,
  zIndex: 0,
});

export const StyledIcon = styled(GatsbyImage)(({ theme }) => ({
  marginBottom: theme.spacing(4),
}));

export const StyledImage2 = styled(GatsbyImage)({
  width: '100%',
});

export const StyledMobileImage = styled(GatsbyImage)(({ theme }) => ({
  width: '100%',
  [theme.breakpoints.down('md')]: {
    display: 'block',
  },
  [theme.breakpoints.up('md')]: {
    display: 'none',
  },
}));

export const StyledDesktopImage = styled(GatsbyImage)(({ theme }) => ({
  width: '100%',
  [theme.breakpoints.up('md')]: {
    display: 'block',
  },
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));

export const StyledHeroImageDesktop = styled(GatsbyImage)(({ theme }) => ({
  width: '100%',
  [theme.breakpoints.up('md')]: {
    display: 'inline',
  },
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));

export const StyledHeroImageMobile = styled(GatsbyImage)(({ theme }) => ({
  width: '100%',
  [theme.breakpoints.up('md')]: {
    display: 'none',
  },
  [theme.breakpoints.down('md')]: {
    display: 'inline',
  },
}));

export const StyledImage8 = styled(GatsbyImage)(({ theme }) => ({
  display: 'block',
  [theme.breakpoints.up('xs')]: {
    width: '100%',
    height: 'auto',
  },
  [theme.breakpoints.up('xs')]: {
    width: '288px',
    height: '312px',
  },
}));
