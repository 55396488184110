import React from 'react';
import { StrapiCaseStudySection3 } from '@/api/case-studies';
import { Box } from '@mui/material';
import getGatsbyImageData from '@/utils/getGatsbyImageData';
import { StyledImage2 } from '@/components/sections/our-work-detail/OurWorkDetail.style';

export type Props = {
  strapiSection: StrapiCaseStudySection3;
};

export const Section3 = ({ strapiSection }: Props) => {
  const imageData = getGatsbyImageData(strapiSection?.image?.data);
  return (
    <Box>
      {imageData && (
        <StyledImage2
          alt={strapiSection?.image?.data?.attributes?.alternativeText || ''}
          image={imageData}
        />
      )}
    </Box>
  );
};
