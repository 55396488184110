import React from 'react';
import { Box, Typography } from '@mui/material';
import { GatsbyImage } from 'gatsby-plugin-image';
import { StrapiImage } from '@/api/types';
import { MainColumn } from '@/components/main-column';
import getGatsbyImageData from '@/utils/getGatsbyImageData';

type Props = {
  image: StrapiImage;
  title: string;
  description: string;
};

export const SectionWithCenteredText = ({
  image,
  description,
  title,
}: Props) => {
  const imageData = getGatsbyImageData(image);
  return (
    <MainColumn
      sx={{
        textAlign: 'center',
        py: { xs: 8, md: 10 },
      }}
    >
      {imageData && (
        <Box
          sx={{
            mb: 4,
            img: {
              width: { xs: 64, md: 80 },
              height: 'auto',
            },
          }}>
          <GatsbyImage
            alt={image?.attributes?.alternativeText || ''}
            image={imageData}
          />
        </Box>
      )}
      <Box sx={{ maxWidth: '808px', mx: 'auto' }}>
        <Typography variant="h3" sx={{ mb: 4 }}>
          {title}
        </Typography>
        <Typography variant="body1">{description}</Typography>
      </Box>
    </MainColumn>
  );
};
