import React from 'react';
import { StrapiCaseStudySection8 } from '@/api/case-studies';
import { Box, Container, Grid, Typography } from '@mui/material';
import { MainColumn } from '@/components/main-column';
import { QuoteMark } from '@/icons/QuoteMark';
import { MarkdownContent } from '@/components/markdown-content';
import getGatsbyImageData from '@/utils/getGatsbyImageData';
import { StyledImage8 } from '@/components/sections/our-work-detail/OurWorkDetail.style';
import { BackLink } from '@/components/back-link';

export type Props = {
  strapiSection: StrapiCaseStudySection8;
};

export const Section8 = ({ strapiSection }: Props) => {
  const imageData = getGatsbyImageData(strapiSection?.image?.data);
  return (
    <>
      <MainColumn sx={{ py: 9 }}>
        <Container sx={{ width: { lg: '1016px', xs: 'auto' } }} disableGutters>
          <Grid
            container
            sx={{
              flexWrap: {
                sx: 'wrap',
                sm: 'nowrap',
              },
            }}
          >
            <Grid xs={12} sm={'auto'} item>
              <Box
                textAlign={'right'}
                sx={{
                  marginBottom: '-1px',
                  svg: { display: 'block', marginRight: 'auto' },
                  display: { xs: 'block', sm: 'none' },
                }}
              >
                <QuoteMark />
              </Box>
              {imageData && (
                <StyledImage8
                  alt={strapiSection?.image?.data?.attributes?.alternativeText || ''}
                  image={imageData}
                />
              )}
              <Box
                textAlign={'right'}
                sx={{
                  svg: { display: 'block', marginLeft: 'auto' },
                  display: { xs: 'none', sm: 'block' },
                }}
              >
                <QuoteMark />
              </Box>
            </Grid>
            <Grid
              xs={12}
              item
              sx={{
                px: { sm: 6, xs: 2 },
                py: { xs: 3, md: 8, lg: 10 },
                backgroundColor: 'greyscale.persian',
                marginBottom: '1px',
              }}
            >
              <Box>
                <MarkdownContent
                  color={'white'}
                  content={strapiSection?.paragraph}
                />
              </Box>
              <Typography
                variant="body2"
                sx={{
                  fontWeight: 'medium',
                  color: 'white',
                }}
              >
                {strapiSection?.text}
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </MainColumn>
      <Container
        maxWidth={false}
        disableGutters
        sx={{ backgroundColor: 'greyscale.cultured' }}
      >
        <MainColumn
          sx={{
            py: { xs: 6, md: 9 },
          }}
        >
          <BackLink
            to={strapiSection?.link?.url || ''}
            text={strapiSection?.link?.text}
          />
        </MainColumn>
      </Container>
    </>
  );
};
