import { StrapiMenu } from '@/api/menu';
import { StrapiFooter } from '@/api/footer';
import { Menu } from '@/components/menu';
import { Footer } from '@/components/footer';
import React from 'react';
import { StrapiCaseStudy } from '@/api/case-studies';
import { Section1 } from '@/components/sections/our-work-detail/Section1';
import { Section8 } from '@/components/sections/our-work-detail/Section8';
import { Section7 } from '@/components/sections/our-work-detail/Section7';
import { Section6 } from '@/components/sections/our-work-detail/Section6';
import { Section5 } from '@/components/sections/our-work-detail/Section5';
import { Section4 } from '@/components/sections/our-work-detail/Section4';
import { Section3 } from '@/components/sections/our-work-detail/Section3';
import { Section2 } from '@/components/sections/our-work-detail/Section2';
import { Head as HeadComponent } from '@/components/head';

export default function CaseStudy({
  pageContext: {
    strapiMenu,
    strapiFooter,
    strapiCaseStudy: {
      attributes: {
        metadata,
        name,
        icon,
        image,
        description,
        section1,
        section2,
        section3,
        section4,
        section5,
        section6,
        section7,
        section8,
      },
    },
  },
}: {
  pageContext: {
    strapiMenu: StrapiMenu;
    strapiFooter: StrapiFooter;
    strapiCaseStudy: StrapiCaseStudy;
  };
}) {
  const headMeta = {
    title: 'Treeline Interactive',
    meta: {
      title: 'Treeline Interactive - Case study',
      description: 'Treeline Interactive - Case study',
      keywords: 'case study, mobile app development, mobile application development, android app development, mobile app development, mobile application development, ios developer, ios development',
    },
  };

  return (
    <>
      <HeadComponent
        defaultTitle={headMeta.title}
        defaultMeta={headMeta.meta}
        metadata={metadata}
        image={metadata?.OGImage?.data?.attributes.url
          || strapiMenu.logo.data.attributes.url} />
      <Menu strapiMenu={strapiMenu} />
      <Section1
        strapiSection={section1}
        name={name}
        icon={icon}
        image={image}
        description={description}
      />
      <Section2 strapiSection={section2} />
      <Section3 strapiSection={section3} />
      <Section4 strapiSection={section4} />
      <Section5 strapiSection={section5} />
      <Section6 strapiSection={section6} />
      <Section7 strapiSection={section7} />
      <Section8 strapiSection={section8} />
      <Footer strapiFooter={strapiFooter} />
    </>
  );
}
