import React from 'react';
import { Container, Box } from '@mui/material';
import { StrapiCaseStudySection7 } from '@/api/case-studies';
import { MainColumn } from '@/components/main-column';
import getGatsbyImageData from '@/utils/getGatsbyImageData';
import { StyledHeroImageDesktop, StyledHeroImageMobile } from '@/components/sections/our-work-detail/OurWorkDetail.style';

export type Props = {
  strapiSection: StrapiCaseStudySection7;
};

export const Section7 = ({ strapiSection }: Props) => {
  const mobileImageData = getGatsbyImageData(
    strapiSection?.image?.mobile?.data,
    {}
  );
  const desktopImageData = getGatsbyImageData(
    strapiSection?.image?.desktop?.data,
    {}
  );
  return (
    <Box
      sx={{
        position: 'relative',
      }}
    >
      <Container
        disableGutters
        maxWidth={false}
        sx={{
          position: 'absolute',
          top: { xs: '5%', md: '15%' },
          bottom: 0,
          backgroundColor: 'greyscale.cultured',
        }}
      >
        <MainColumn
          sx={{
            height: '100%',
          }}
        >
          <Box
            sx={{
              height: '100%',
              backgroundColor: 'greyscale.cultured',
            }}
          />
        </MainColumn>
      </Container>
      <Box
        sx={{ position: 'relative' }}
      >
        {mobileImageData && (
          <StyledHeroImageMobile
            alt={strapiSection?.image?.mobile?.data?.attributes?.alternativeText || ''}
            image={mobileImageData}
          />
        )}
        {desktopImageData && (
          <StyledHeroImageDesktop
            alt={strapiSection?.image?.desktop?.data?.attributes?.alternativeText || ''}
            image={desktopImageData}
          />
        )}
      </Box>
    </Box>
  );
};
