import React from 'react';
import { StrapiCaseStudySection1 } from '@/api/case-studies';
import { StrapiImage } from '@/api/types';
import { Box, Container, Grid, Typography } from '@mui/material';
import { MENU_HEIGHT } from '@/constants';
import { MainColumn } from '@/components/main-column';
import { mainColumnGutters } from '@/gatsby-theme-material-ui-top-layout/spacing';
import getGatsbyImageData from '@/utils/getGatsbyImageData';
import { StyledImage1, StyledIcon } from '@/components/sections/our-work-detail/OurWorkDetail.style';
import { GatsbyImage } from 'gatsby-plugin-image';

export type Props = {
  strapiSection: StrapiCaseStudySection1;
  description: string;
  name: string;
  icon: {
    data: StrapiImage;
  };
  image: {
    data: StrapiImage;
  };
};

export const Section1 = ({
  strapiSection,
  image,
  icon,
  name,
  description,
}: Props) => {
  const bottomSectionOverlap = 100;
  const imageData = getGatsbyImageData(image.data);
  const iconData = getGatsbyImageData(icon.data);
  return (
    <>
      <Container
        maxWidth={false}
        disableGutters
        sx={{
          display: 'flex',
          alignItems: 'center',
          mt: `${MENU_HEIGHT}px`,
          minHeight: `calc(100vh - ${MENU_HEIGHT}px + ${bottomSectionOverlap}px)`,
          position: 'relative',
        }}
      >
        {imageData && (
          <StyledImage1
            image={imageData}
            alt={image.data.attributes.alternativeText || ''}
          />
        )}
        <Box
          sx={{
            height: '100%',
            width: '100%',
            position: 'absolute',
            top: 0,
            left: 0,
            objectFit: 'cover',
            background:
              'linear-gradient(114.91deg, #031520 18.09%, rgba(3, 21, 32, 0.3) 100%)',
          }}
        />
        <MainColumn
          sx={{
            position: 'relative',
            pt: {
              xs: 3,
              md: 10,
            },
          }}
        >
          <Grid container sx={{ flexDirection: 'column', mb: 12 }}>
            <Grid item xs={12}>
              {iconData && (
                <StyledIcon
                  image={iconData}
                  alt={icon.data.attributes.alternativeText || ''}
                />
              )}
              <Typography variant="h1" color="white" sx={{ mb: 4 }}>
                {name}
              </Typography>
            </Grid>
            <Grid item sm={7}>
              <Typography
                sx={{
                  color: 'greyscale.beau',
                  mb: 4,
                }}
              >
                {description}
              </Typography>
            </Grid>
          </Grid>
        </MainColumn>
      </Container>
      <MainColumn
        sx={{
          position: 'relative',
          px: { xs: '0px', sm: '0px' },
          mt: { md: `-${bottomSectionOverlap}px` },
        }}
      >
        <Grid
          container
          sx={{
            backgroundColor: 'greyscale.cultured',
            py: { sm: '0px', md: 5 },
            px: { ...mainColumnGutters, md: '0px', lg: '0px' },
          }}
        >
          {strapiSection?.details?.map(
            ({ title, description: desc, image: img }, index) => {
              const innerImageData = getGatsbyImageData(img.data);
              return (
                <Grid
                  key={`${title}${index}`}
                  item
                  xs={12}
                  md={4}
                  sx={{
                    borderWidth: '0px',
                    borderStyle: 'solid',
                    borderColor: 'greyscale.beau',
                    ...(strapiSection.details?.length &&
                      index + 1 < strapiSection.details.length && {
                      borderRightWidth: {
                        xs: '0px',
                        md: 1,
                      },
                      borderBottomWidth: {
                        xs: 1,
                        md: '0px',
                      },
                    }),
                  }}
                >
                  <Grid
                    container
                    sx={{
                      alignItems: 'flex-start',
                      flexWrap: 'nowrap',
                      py: { xs: 4, md: 3 },
                      pl: { xs: '0px', md: 4, lg: 6 },
                    }}
                  >
                    <Grid item mr={3} sx={{ fontSize: '0px' }}>
                      {innerImageData && (
                        <GatsbyImage
                          key={index}
                          alt={img.data.attributes.alternativeText || ''}
                          image={innerImageData}
                        />
                      )}
                    </Grid>
                    <Grid item>
                      <Typography
                        variant="body3"
                        paragraph
                        sx={{
                          fontWeight: 'bold',
                          color: 'greyscale.steel',
                          mb: 0,
                        }}
                      >
                        {title}
                      </Typography>
                      <Typography fontWeight={'500'}>{desc}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              );
            },
          )}
        </Grid>
      </MainColumn>
    </>
  );
};
