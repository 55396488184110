import React, { FC } from 'react';
import { GatsbyLink } from 'gatsby-theme-material-ui';
import { Box, Link, LinkProps, Typography } from '@mui/material';
import { LinkRightArrowLarge } from '@/icons/LinkRightArrowLarge';

interface Props extends LinkProps {
  to: string;
  text: string;
}

export const BackLink: FC<Props> = ({ to, text, ...props }) => (
  <Link
    component={GatsbyLink}
    to={to}
    {...props}
    sx={{
      display: 'inline-flex',
      alignItems: 'center',
      typography: 'h2',
      fontSize: {
        xs: 'h3.fontSize',
      },
      color: 'greyscale.persian',
      '&:hover': {
        textDecoration: 'underline',
      },
      ...(Array.isArray(props.sx) ?
        Object.assign({}, ...props.sx) :
        props.sx
      ),
    }}
  >
    <Typography
      component="span"
      variant="inherit"
      sx={{ mr: 2 }}
    >
      {text}
    </Typography>
    <Box
      component="span"
      sx={{
        fontSize: 0,
        svg: {
          maxWidth: { xs: 32, md: 40 },
          maxHeight: { xs: 32, md: 40 },
        },
      }}
    >
      <LinkRightArrowLarge />
    </Box>
  </Link>
);