import React from 'react';
import { StrapiCaseStudySection4 } from '@/api/case-studies';
import { SectionWithCenteredText } from '@/components/section-with-centered-text';

export type Props = {
  strapiSection: StrapiCaseStudySection4;
};

export const Section4 = ({ strapiSection }: Props) => (
  <SectionWithCenteredText
    description={strapiSection?.component?.description}
    title={strapiSection?.component?.title}
    image={strapiSection?.component?.image?.data}
  />
);
