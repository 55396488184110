import React, { FC } from 'react';
import { StrapiCaseStudySection5 } from '@/api/case-studies';
import { Container, Box } from '@mui/material';
import { MainColumn } from '@/components/main-column';
import getGatsbyImageData from '@/utils/getGatsbyImageData';
import { StyledMobileImage, StyledDesktopImage } from '@/components/sections/our-work-detail/OurWorkDetail.style';

interface ICaseStudySection5 {
  strapiSection?: StrapiCaseStudySection5;
}

export const Section5: FC<ICaseStudySection5> = ({ strapiSection }) => (
  <Box
    sx={{
      position: 'relative',
    }}
  >
    <Container
      disableGutters
      maxWidth={false}
      sx={{
        position: 'absolute',
        height: { xs: '97%', md: '92%' },
      }}
    >
      <MainColumn
        sx={{
          height: '100%',
        }}
      >
        <Box
          sx={{
            height: '100%',
            backgroundColor: 'greyscale.cultured',
          }}
        />
      </MainColumn>
    </Container>
    {strapiSection?.images?.map(
      (image, index) => {
        const mobileImageData = getGatsbyImageData(image.mobile.data);
        const desktopImageData = getGatsbyImageData(image.desktop.data);
        return (
          <Box
            key={index}
            sx={{ position: 'relative' }}
          >
            {mobileImageData && (
              <StyledMobileImage
                image={mobileImageData}
                alt={image.mobile?.data?.attributes?.alternativeText || ''}
              />
            )}
            {desktopImageData && (
              <StyledDesktopImage
                image={desktopImageData}
                alt={image.desktop?.data?.attributes?.alternativeText || ''}
              />
            )}
          </Box>
        );
      }
    )}
  </Box>
);
