import React from 'react';

export const QuoteMark = () => (
  <svg
    width="76"
    height="77"
    viewBox="0 0 76 77"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      width="76"
      height="76"
      transform="translate(0 0.0473633)"
      fill="#04456A"
    />
    <path
      d="M28.3161 54.0474C26.5183 54.0474 25.0473 53.6664 23.9032 52.9045C22.7591 52.1426 21.8194 51.1691 21.0839 49.9839C20.2667 48.5447 19.6946 47.0632 19.3677 45.5394C19.1226 43.931 19 42.6188 19 41.6029C19 37.4548 20.0215 33.6876 22.0645 30.3013C24.1075 26.9151 27.2946 24.1638 31.6258 22.0474L32.729 24.3331C30.1957 25.4336 27.9892 27.1691 26.1097 29.5394C24.3118 31.9098 23.4129 34.3225 23.4129 36.7775C23.4129 37.7934 23.5355 38.6823 23.7806 39.4442C25.0882 38.3437 26.6 37.7934 28.3161 37.7934C30.4409 37.7934 32.2796 38.513 33.8323 39.9521C35.3849 41.3913 36.1613 43.3807 36.1613 45.9204C36.1613 48.2907 35.3849 50.2378 33.8323 51.7616C32.2796 53.2855 30.4409 54.0474 28.3161 54.0474ZM49.1548 54.0474C47.357 54.0474 45.886 53.6664 44.7419 52.9045C43.5978 52.1426 42.6581 51.1691 41.9226 49.9839C41.1054 48.5447 40.5333 47.0632 40.2065 45.5394C39.9613 43.931 39.8387 42.6188 39.8387 41.6029C39.8387 37.4548 40.8602 33.6876 42.9032 30.3013C44.9462 26.9151 48.1333 24.1638 52.4645 22.0474L53.5677 24.3331C51.0344 25.4336 48.828 27.1691 46.9484 29.5394C45.1505 31.9098 44.2516 34.3225 44.2516 36.7775C44.2516 37.7934 44.3742 38.6823 44.6194 39.4442C45.9269 38.3437 47.4387 37.7934 49.1548 37.7934C51.2796 37.7934 53.1183 38.513 54.671 39.9521C56.2237 41.3913 57 43.3807 57 45.9204C57 48.2907 56.2237 50.2378 54.671 51.7616C53.1183 53.2855 51.2796 54.0474 49.1548 54.0474Z"
      fill="white"
    />
  </svg>
);
